import { SchoolYear, makeSchoolYear } from './schools'
import { Individual, makeIndividual } from './people'
import {
  Discount, Invoice, makeDiscount, makeInvoice, calculatePrice, Sale, makeSale,
  AnalyticAccount, GeneralAccount, makeAnalyticAccount, makeGeneralAccount
} from './payments'

export const FAMILY_ADHESION: number = 1
export const INDIVIDUAL_ADHESION: number = 2

export class AdhesionTypePrice {
  constructor(
    public id: number,
    public schoolYear: SchoolYear,
    public price: number
  ) {
  }
}

export class AdhesionType {
  constructor(
    public id: number,
    public name: string,
    public applyTo: number,
    public prices: AdhesionTypePrice[],
    public analyticAccount: AnalyticAccount,
    public generalAccount: GeneralAccount
  ) {
  }

  public applyToIndividual(): boolean {
    return this.applyTo === INDIVIDUAL_ADHESION
  }

  public applyToEntity(): boolean {
    return this.applyTo === FAMILY_ADHESION
  }

  public getLabel(): string {
    if (this.applyTo === FAMILY_ADHESION) {
      return 'Famille'
    } else {
      return 'Individuelle'
    }
  }
}

export class Adhesion {
  constructor(
    public id: number,
    public cardNumber: string,
    public schoolYear: SchoolYear,
    public isCancelled: boolean,
    public createdOn: Date,
    public createdBy: string,
    public adhesionType: AdhesionType,
    public entity: number,
    public individual: Individual|null,
    public invoice: Invoice|null,
    public price: number|null,
    public discounts: Discount[],
    public sale: Sale|null
  ) {
  }

  public getPrice(): number|null {
    if (this.price === null) {
      return null
    } else {
      return calculatePrice(this.price || 0, this.discounts)
    }
  }

  public getSales(extraOnly = false): Sale[] {
    // pour compatibilité avec les activités
    if (this.sale && !extraOnly) {
      return [this.sale]
    }
    return []
  }
}

export function makeAdhesionTypePrice(jsonData: any = null): AdhesionTypePrice {
  if (!jsonData) {
    jsonData = {}
  }
  return new AdhesionTypePrice(
    jsonData.id || 0,
    makeSchoolYear(jsonData.school_year),
    jsonData.price ? +jsonData.price : 0
  )
}

export function makeAdhesionType(jsonData: any = null): AdhesionType {
  if (!jsonData) {
    jsonData = {}
  }
  let prices = jsonData.prices || []
  return new AdhesionType(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.apply_to || FAMILY_ADHESION,
    prices.map((elt: any) => makeAdhesionTypePrice(elt)),
    makeAnalyticAccount(jsonData.analytic_account) || null,
    makeGeneralAccount(jsonData.general_account) || null
  )
}

export function makeAdhesion(jsonData: any = null): Adhesion {
  if (!jsonData) {
    jsonData = {}
  }
  let discounts = jsonData.discounts || []
  return new Adhesion(
    jsonData.id || 0,
    jsonData.card_number || '',
    makeSchoolYear(jsonData.school_year),
    !!jsonData.is_cancelled,
    jsonData.created_on,
    jsonData.created_by,
    makeAdhesionType(jsonData.adhesion_type),
    jsonData.entity || 0,
    jsonData.individual ? makeIndividual(jsonData.individual) : null,
    jsonData.invoice ? makeInvoice(jsonData.invoice) : null,
    (jsonData.price !== null) ? +jsonData.price : null,
    discounts.map((elt: any) => makeDiscount(elt)),
    jsonData.sale ? makeSale(jsonData.sale) : null
  )
}
